import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import "../../css/typography-cerabielefeld.css"

export default function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <>
      <link
        href="https://static.convaise.com/popup/regioit/popup-v4-regioit-bielefeld.min.css?v=1.0.0"
        rel="stylesheet"
      ></link>
      <div
        id="convaise-wrapper"
        class="convaisecss-clear"
        data-name="Dienstleistungsfinder"
        data-gdpr-mode="navbar"
        data-gdpr-url="https://www.bielefeld.de/datenschutzerklaerung"
        data-persist-chat="true"
        data-show-name-button="true"
        data-news="false"
        data-botid="ddxng5wgkx4esxti"
        data-src="https://bots.convaise.com/regioit/bielefeld-iframe/"
        data-avatar-url="https://static.convaise.com/avatars/bielefeld/BIE-inaktiv.svg?v=1.0.0"
      ></div>
      <Helmet>
        <script src={withPrefix("popup-v4.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}
